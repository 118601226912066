<template>
  <div>
    <Message
        :message="message"
        @closeAlert="message.show = false"
    ></Message>
    <DialogHeadline
        :dialog="dialog"
        :title="'Neuen Dienstleister anlegen'"
        :values="vendorCreateFields"
        :successButton="'Erstellen'"
        :icon="'mdi mdi-handshake'"
        :headLines="[{text: 'Haupt-Ansprechpartner', position: 5}]"
        :cut="5"
        @hideDialog="hideDialog"
        @emitNewData="createVendor"
    ></DialogHeadline>
  </div>

</template>

<script>
import {mapGetters} from "vuex";
import {error, msgObj, success} from "@/helper/helper";

const Message = () => import("@/components/generalUI/Message");
const DialogHeadline = () => import("@/components/dialog/FormDialogNew");

export default {
  name: "CreateCustomer",
  components: {
    DialogHeadline,
    Message
  },
  computed: {
    ...mapGetters('auth', {
      user: 'user',
    }),
    ...mapGetters('settings', {
      categories: 'categoryNames',
      stilNames: 'stilNames',
      vendorServiceNames: 'vendorServiceNames'
    })
  },
  data() {
    return {
      message: msgObj(),
      categorieNames: [],
      vendorCreateFields: [
        {
          title: 'Unternehmensname (optional)',
          value: '',
          id: 0,
          type: 'string'
        },
        {
          title: 'Kategorie',
          value: null,
          id: 1,
          items: [],
          type: 'autoComplete',
        },
        {
          title: 'Stil',
          value: '',
          id: 2,
          type: 'multipleAutoComplete',
          items: this.stilNames
        },
        {
          title: 'Preisklasse',
          value: null,
          id: 3,
          items: [{text:'€', value:1}, {text:'€€', value:2}, {text:'€€€', value:3}],
          type: 'autoComplete',
        },
        {
          title: 'Leistungen',
          values: null,
          id: 4,
          items: this.vendorServiceNames,
          type: 'multipleAutoComplete',
        },
        {
          title: 'Vorname',
          value: '',
          id: 5,
          type: 'string'
        },
        {
          title: 'Nachname',
          value: '',
          id: 6,
          type: 'string'
        },
        {
          title: 'Rolle /  Tätigkeit',
          value: '',
          id: 7,
          type: 'string'
        },
        {
          title: 'Telefonnummer',
          value: '',
          id: 8,
          type: 'string'
        },
        {
          title: 'E-Mail Adresse',
          value: '',
          id: 9,
          type: 'string'
        },

      ]
    }
  },
  mounted() {
    this.$store.dispatch('settings/getCategories', {
      uid: this.user.id,
    }).catch((err) => {
      this.message = error(err)
    }).then(()=>{
      this.$store.dispatch('settings/getVendorServices', {
        uid: this.user.id,
      }).then(()=>{
        this.$store.dispatch('settings/getStil', {
          uid: this.user.id,
        }).then(()=>{
          this.vendorCreateFields = [
            {
              title: 'Unternehmensname (optional)',
              value: '',
              id: 0,
              type: 'string'
            },
            {
              title: 'Kategorie',
              values: null,
              id: 1,
              items: this.categories,
              type: 'autoComplete',
            },
            {
              title: 'Stil',
              value: null,
              id: 2,
              items: this.stilNames,
              type: 'multipleAutoComplete'
            },
            {
              title: 'Preisklasse',
              value: null,
              id: 3,
              items: [{text:'€', value:1}, {text:'€€', value:2}, {text:'€€€', value:3}],
              type: 'autoComplete',
            },
            {
              title: 'Leistungen',
              value: null,
              id: 4,
              items: this.vendorServiceNames,
              type: 'multipleAutoComplete',
            },
            {
              title: 'Vorname',
              value: '',
              id: 5,
              type: 'string'
            },
            {
              title: 'Nachname',
              value: '',
              id: 6,
              type: 'string'
            },
            {
              title: 'Rolle /  Tätigkeit',
              value: '',
              id: 7,
              type: 'string'
            },
            {
              title: 'Telefonnummer',
              value: '',
              id: 8,
              type: 'string'
            },
            {
              title: 'E-Mail Adresse',
              value: '',
              id: 9,
              type: 'string'
            },
          ]
        }).catch((err) => {
          this.message = {
            show: true,
            text: err,
            color: "error",
          }
          setTimeout(() => this.message.show = false, 5000);
        })

      }).catch((err) => {
        this.message = {
          show: true,
          text: err,
          color: "error",
        }
        setTimeout(() => this.message.show = false, 5000);
      })
    })
  },
  props: {
    dialog: Boolean,
    openAfterCreate: {
      type: Boolean,
      default() {
        return false
      }
    },
  },
  methods: {
    hideDialog() {
      this.$emit('hideDialog')
    },
    createVendor(newValues) {
      let newVendor = this.getVendorFromInput(newValues)
      console.log(newVendor)
      this.$store.dispatch('vendor/createVendor', {
        newVendor,
        uid: this.user.id,
        openAfterCreate: this.openAfterCreate
      }).then(() => {
        this.hideDialog()
        this.message = success('Kunde wurde erfolgreich angelegt.')
        this.$emit('createVendor')

      }).catch((err)=>{
        this.message = error(err)
      })
    },
    getVendorFromInput(newValues) {
      let newVendor = {
        firstname: '',
        lastname: '',
        name: '',
        email: '',
        tel: '',
        contactRole: '',
        category: '',
        stil:null,
        priceClass:null,
        services:null,
      }
      for (let value of newValues) {
        switch (value.title) {
          case 'Vorname':
            newVendor.firstname = value.value
            break;
          case 'Nachname':
            newVendor.lastname = value.value
            break;
          case 'Unternehmensname (optional)':
            newVendor.name = value.value
            break;
          case 'Telefonnummer':
            newVendor.tel = value.value
            break;
          case 'E-Mail Adresse':
            newVendor.email = value.value
            break;
          case 'Rolle /  Tätigkeit':
            newVendor.role = value.value
            break;
          case 'Kategorie':
            newVendor.category = value.value
            break;
          case 'Leistungen':
            newVendor.services = value.value
            break;
          case 'Preisklasse':
            console.log(value)
            newVendor.priceClass = value.value
            break;
          case 'Stil':
            console.log(value)
            newVendor.stil = value.value
            break;
        }
      }
      return newVendor
    },

  },
}
</script>
